
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { Component, Prop, Vue } from "vue-property-decorator";
import { paymentsService } from "@/services/api/payments.service";
import OverlayModule from "@/store/modules/overlay.module";
import { userService } from "@/services/api/user.service";
import AuthModule from "@/store/modules/auth.module";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class ConfirmationPage extends Vue {
  @Prop({ required: false, default: null, type: String })
  private upgradeYear: string;

  private roleId: number;
  private downgrade = false;
  private changePlan = false;
  private finish = false;

  private promotionPlan: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private async created() {
    overlayModule.showOverlay();
    var upgrade = this.$route.query.upgrade;
    var roleId = this.$route.query.roleId;
    overlayModule.showOverlay();
    var duration = (await userService.subscriptionDuration()).data;
    this.downgrade = (await paymentsService.getChangedPlan()).data;
    if (upgrade) {
      overlayModule.showOverlay();
      overlayModule.showOverlay();
      await paymentsService.saveSubscription();
      this.promotionPlan = (await userService.GetSubscription()).data;
      authModule.setProfileRoleID(this.promotionPlan.roleId);
      this.changePlan = true;
    }
    if (this.upgradeYear) {
      this.promotionPlan = (await userService.GetSubscription()).data;
      authModule.setProfileRoleID(this.promotionPlan.roleId);
      this.changePlan = true;
    }
    if (duration != 0 && this.downgrade) {
      // this.promotionPlan = (await personalDataService.GetSubscription()).data;
      this.changePlan = true;
      this.downgrade = true;
      var roleIdDowngrade = (await userService.GetFuturePlan()).data;
      switch (roleIdDowngrade) {
        case 1:
          this.promotionPlan.nameOffert = "Administrator";
          break;
        case 3:
          this.promotionPlan.nameOffert = this.$tc("general.Base");
          break;
        case 4:
          this.promotionPlan.nameOffert = this.$tc("general.Standard");
          break;
        case 5:
          this.promotionPlan.nameOffert = this.$tc("general.Pro");
          break;
        case 6:
          this.promotionPlan.nameOffert = this.$tc("general.Unlimited");
          break;
        default:
          return "";
      }
    }
    if (duration == 0 && !upgrade) {
      overlayModule.showOverlay();
      this.promotionPlan = (await userService.GetSubscription()).data;
    }
    this.finish = true;
  }

  private async back() {
    overlayModule.showOverlay();
    this.$router.push("/account/payments");
  }
}
